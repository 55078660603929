
// Converted Variables


// Custom Media Query Variables


/*@font-face {
  font-family: 'martelultralight';
  src: url("../fonts/martel-ultralight-webfont.woff2") format('woff2'), url("../fonts/martel-ultralight-webfont.woff") format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}*/
@font-face {
  font-family: 'martellight';
  src: url("../fonts/martel-light-webfont.woff2") format('woff2'), url("../fonts/martel-light-webfont.woff") format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'martelregular';
  src: url("../fonts/martel-regular-webfont.woff2") format('woff2'), url("../fonts/martel-regular-webfont.woff") format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'marteldemibold';
  src: url("../fonts/martel-demibold-webfont.woff2") format('woff2'), url("../fonts/martel-demibold-webfont.woff") format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'martelbold';
  src: url("../fonts/martel-bold-webfont.woff2") format('woff2'), url("../fonts/martel-bold-webfont.woff") format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'martelextrabold';
  src: url("../fonts/martel-extrabold-webfont.woff2") format('woff2'), url("../fonts/martel-extrabold-webfont.woff") format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'martelheavy';
  src: url("../fonts/martel-heavy-webfont.woff2") format('woff2'), url("../fonts/martel-heavy-webfont.woff") format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Glyphicons Halflings";
  src: url("../fonts/glyphicons-halflings-regular.eot");
  src: url("../fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("../fonts/glyphicons-halflings-regular.woff") format("woff"), url("../fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular") format("svg");
}